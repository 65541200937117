<template>
    <div>
        <div
            class="progress"
            v-show="progress"
        >
            <v-progress-circular
                indeterminate
                :size="150"
                :width="15"
                color="grey"
                class="circular"
            ></v-progress-circular>
        </div>
        <v-alert
            color="red"
            type="error"
            v-if="error.length > 0"
        >
            <ul>
                <li
                    v-for="(item, index) in error"
                    :key="'error' + index">
                    {{ item }}
                </li>
            </ul>
        </v-alert>
        <p class="text-h6 font-weight-bold teal--text text--darken-1">
            共用部分の計算結果PDFのアップロード
        </p>
        <v-row>
            <v-col cols=12>
                <v-alert outlined color="green" height=200>
                    <p
                        class="pa-5 teal--text text--darken-1 text-body-2 font-weight-bold"
                    >
                        共同住宅の共用部分について、建築物のエネルギー消費性能計算プログラム（非住宅版）で計算・ダウンロードしたPDFをアップロードして下さい。
                    </p>
                    <v-row justify="center" class="mx-6">
                            <v-alert outlined  width=100% color="grey" class="pa-1">
                                <vue-dropzone ref="myVueDropzone" id="dropzone"
                                    :options="dropzoneOptions"
                                    :useCustomSlot="true"
                                    @vdropzone-success="fileUploaded"
                                    @vdropzone-max-files-exceeded="maxFileExceededError"
                                    @vdropzone-error="fileUploadFailed"
                                    @vdropzone-files-added="filesAdded"
                                    @vdropzone-sending="fileSending"
                                    style="background-color: #f5f5f5;"
                                >
                                    <div class="teal--text text--darken-1 text-body-2 font-weight-bold ">
                                        ファイルをここにドラッグ&ドロップまたは<span class="text-decoration-underline">参照</span>
                                    </div>
                                </vue-dropzone>
                            </v-alert>
                    </v-row>
                </v-alert>
            </v-col>
        </v-row>
        <div v-if="isCommonAreaFileImported && commonAreaFileName">
            <span class="text-body-2 font-weight-bold teal--text text--darken-1">
                以下のファイルは読み込み済みです。
            </span>
            <v-row>
                <v-col cols=12>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td
                                        class="text-body-2 font-weight-medium teal--text text--darken-1">
                                        <v-hover v-slot="{ hover }">
                                            <div>
                                                {{ commonAreaFileName }}
                                                <v-btn
                                                    v-if="hover"
                                                    icon
                                                    color="red"
                                                    x-small
                                                    dark
                                                   @click="deleteCommonAreaFileName"
                                                >
                                                    <v-icon>mdi-close-thick</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-hover>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </div>
        <v-row class="pl-5 pr-5">
            <v-btn
                @click="toHouseholdUpload"
                rounded-lg
                color="#A0A0A0"
                width="150"
                height="60"
                class="mt-15 white--text font-weight-bold text-h6"
            >
                <v-icon large>mdi-chevron-left</v-icon>
                <v-spacer></v-spacer>
                戻る
                <v-spacer></v-spacer>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                @click="toAggreagateResult"
                :disabled="!isCommonAreaFileImported && commonAreaType"
                color="#025F59"
                width="200"
                height="60"
                class="mt-15 ml-5 white--text font-weight-bold text-h6"
            >
                <v-spacer></v-spacer>
                集計結果
                <v-spacer></v-spacer>
                <v-icon large>mdi-chevron-right</v-icon>
            </v-btn>
        </v-row>
    </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import { mapGetters, mapActions } from 'vuex'
// Jobの状態確認の待ち時間用
import { sleep } from '@/util'
import { JobStatus } from '@/mixins/JobStatus'

export default {
    components: {
        vueDropzone: vue2Dropzone
    },
    mixins: [JobStatus],
     data() {
        return {
            dropzoneOptions:{
              url: '/api/upload',
              acceptedFiles: '.pdf',
              uploadMultiple: true,
              maxFiles: 1,
              includeStyling: false,
              previewsContainer: false,
              // ファイルをserver側に送信する前の処理
              accept: this.acceptPdf,
              //自動的にキューに追加しない
              autoQueue : false
            },
            // 進行中表示の有無
            progress:false,
        }
    },
    computed: {
        ...mapGetters(['error']),
        ...mapGetters('fileupload', {
            commonAreaFileName:'getCommonAreaFileName',
            errorFileCnt: 'errorFileCnt'
        }),
        ...mapGetters('basicInfo', {
            commonAreaType:'getCommonAreaType',
        }),
        isCommonAreaFileImported(){
            return (this.commonAreaFileName) ? true : false
        }
    },
    methods: {
        ...mapActions('fileupload', {
            updateCommonAreaJobId: 'updateCommonAreaJobId',
            updateCommonAreaFileName: 'updateCommonAreaFileName',
            getHouseholdResults: 'getHouseholdResults',
            showErrorFileMessage: 'showErrorFileMessage',
            clearErrorResults: 'clearErrorResults',
        }),

        ...mapActions('result', ['getAggregateResult']),
        ...mapActions([
            'setError',
            'clearError'
        ]),

        // APIにファイルを読み込む前の処理
        acceptPdf(_file, done) {
            this.progress = true
            this.clearError()
            done()
        },

        // ファイルドロップの検知
        filesAdded(file){
            //エラー情報を消す
            this.clearError()
            //dropzoneに追加されたファイルの数が1の場合のときのみキューに追加
            const dz = this.$refs.myVueDropzone.dropzone
            setTimeout(()=>{
                if (this.commonAreaFileName){
                    this.setError("別の計算結果PDFをアップロードする場合は、読み込み済みのファイルを削除してください。")
                    this.$refs.myVueDropzone.dropzone.removeAllFiles(true)
                    this.progress = false
                } else if (file.length != 1) {
                    this.$refs.myVueDropzone.dropzone.removeAllFiles(true)
                    this.progress = false
                } else {
                    dz.enqueueFiles(dz.getAddedFiles())
                }
            }, 1000)
        },

        // ファイルアップロードする際にPDFタイプパラメータを追加
        fileSending(_file, _xhr, formData) {
            formData.append('type', 'commonarea');
        },

        // ファイル読み込み後の処理
        async fileUploaded(file, jobId) {
            // job実行結果の確認
            let status = ''
            do {
                let response = await this.checkJobStatus({jobId:jobId, type:'commonarea', filename: file.name})
                status = response['job_status']
                    await sleep(500)
            } while (status == 'processing')

            // job実行結果の取得
            if (status == 'finished') {
                await this.getHouseholdResults({type: "commonarea", jobId: jobId})

                if (this.errorFileCnt > 0) {
                   this.showErrorFileMessage()
                } else {
                    // 読み込み済みのjobId
                    this.updateCommonAreaJobId({commonAreaJobId:jobId})
                    this.updateCommonAreaFileName({commonAreaFileName:file.name})
                }
                this.clearErrorResults()
            }

            this.progress = false
            this.$refs.myVueDropzone.dropzone.removeAllFiles(true)
        },

        // 最大ファイル数を超える場合のエラー
        maxFileExceededError() {
            this.$refs.myVueDropzone.dropzone.removeAllFiles(true)
            this.setError("ファイルを一つのみアップロードして下さい。")
        },
        // ファイル読み込み失敗の処理
        fileUploadFailed(_files, message, xhr){
            this.progress = false
            this.$refs.myVueDropzone.dropzone.removeAllFiles(true)
            if (message == "You can't upload files of this type."){
                this.setError("PDFファイルを指定してください。")
            }else if (xhr) {
                this.setError("エラーが発生しました。しばらくしてからもう一度お試し下さい。")
            }
        },
        // 住戸部分の計算結果PDFのアップロード画面へ戻る
        toHouseholdUpload() {
            this.clearError()
            this.$router.push('household-upload')
        },
        // 共用部PDFファイル名を削除する処理
        deleteCommonAreaFileName() {
            this.$refs.myVueDropzone.dropzone.removeAllFiles(true)
            this.updateCommonAreaFileName({
                commonAreaFileName: null
            })
            this.updateCommonAreaJobId({
                commonAreaJobId: null,
            })
        },
        //集計結果画面への遷移
        async toAggreagateResult() {
            //一次エネの評価方法が住戸部分のみなら、アップロードされた共用部のPDFは使用しない
            if (!(this.commonAreaType)){
                this.updateCommonAreaFileName({
                    commonAreaFileName: null
                })
                this.updateCommonAreaJobId({
                    commonAreaJobId: null,
                })
            }
            let status = await this.getAggregateResult()
            if (status == 'success') {
                this.clearError()
                this.$router.push('total-result')
            }
            else {
                this.setError("エラーが発生しました。しばらくしてからもう一度お試し下さい。")
            }
        }
    }
}
</script>
<style>
.dropzone {
  min-height: 0px !important;
  border: none;
}
.dropzone .dz-message{
  margin: 0 !important;
}
.progress {
    position: fixed;
    top:0%;
    left: 0%;
    width:100%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}
</style>